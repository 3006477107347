<template>
  <div
    class="showOptions d-flex justify-content-between mb-3 align-items-end"
    style="margin-top: -3rem "
  >
    <div class="ml-3">
      <button
        v-if="isShow"
        class="success"
        variant="success"
        size="sm"
        @click="addHerbarium"
      >
        {{ $t('herbarium_form_page_btn_create') }}
      </button>
    </div>
    <b-nav>
      <b-nav-item-dropdown
        :text="$t('component_nav_for_main_page_select_pages_number')"
        right
        style="margin-top: 25px;"
      >
        <b-dropdown-item href="#" @click="changeShowItemsLess">
          {{ minItems }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="changeShowItemsMore">
          {{ maxItems }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item>
        <b-icon icon="grid" @click="showGrid"></b-icon>
      </b-nav-item>
      <b-nav-item>
        <b-icon icon="list" @click="showList"></b-icon>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showGrid: { type: Function, default: () => {} },
    showList: { type: Function, default: () => {} },
    addHerbarium: { type: Function, default: () => {} },
    changeShowItemsLess: { type: Function, default: () => {} },
    changeShowItemsMore: { type: Function, default: () => {} },
    minItems: { type: Number, default: () => null },
    maxItems: { type: Number, default: () => null }
  },
  computed: {
    ...mapGetters(['getRole']),
    isShow: function() {
      return ['editor', 'admin'].some(el => el === this.getRole)
    }
  }
}
</script>

<style lang="scss" scoped>
.showOptions {
  .success {
    padding: 5px 10px;
    color: #fff;
    &:hover {
      background: #01974f;
    }
  }
  /deep/.nav {
    .dropdown-item:hover {
      background-color: #00b05a;
      color: #ffffff;
    }
    .nav-item {
      .nav-link {
        color: #00b05a;
        .dropdown-toggle {
          color: #00b05a;
        }
        .bi-grid.b-icon.bi,
        .bi-list.b-icon.bi,
        .bi-box-arrow-in-down-left.b-icon.bi {
          width: 30px;
          height: 30px;
          color: #00b05a;
          float: right;
          margin-top: 20px;
          &:hover {
            color: #fff;
            background: #00b05a;
            border: none;
            padding: 2px;
            border-radius: 2px;
          }
        }
      }
    }
    @media (max-width: 960px) {
      .bi-grid.b-icon.bi,
      .bi-list.b-icon.bi {
        width: 25px;
        height: 25px;
        margin-top: 25px;
      }
    }
    @media (max-width: 520px) {
      .bi-grid.b-icon.bi,
      .bi-list.b-icon.bi {
        width: 20px;
        height: 20px;
        margin-top: 28px;
      }
    }
  }
}
</style>

<template>
  <div>
    <HerbariumShowOptions
      :min-items="minItems"
      :max-items="maxItems"
      :show-grid="showGrid"
      :show-list="showList"
      :add-herbarium="herbariumCreate"
      :change-show-items-more="changeShowItemsMore"
      :change-show-items-less="changeShowItemsLess"
    />
    <router-view :items="getData" :loading="isLoading" class="mb-5" />
    <div v-if="getData.length" class="pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="getCount"
        :per-page="getPerPage"
        first-number
        last-number
        @change="fetchHerbariums"
      />
    </div>
  </div>
</template>
<script>
import HerbariumShowOptions from '@/components/HerbariumShowOptions'
import queryString from 'query-string'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    HerbariumShowOptions
  },
  data() {
    return {
      switchType: true,
      perPage: 4,
      totalRows: 0,
      currentPage: 1,
      minItems: 4,
      maxItems: 8
    }
  },
  computed: {
    ...mapGetters('herbarium', ['getData', 'getCount', 'isLoading']),
    ...mapGetters(['getPerPage', 'getCurrentPage'])
  },
  watch: {
    '$route.query': function() {
      if (!location.search) {
        this.fetchData({ limit: this.getPerPage, offset: this.getCurrentPage })
      }
    }
  },
  async mounted() {
    if (!location.search) {
      this.fetchData({ limit: this.getPerPage })
    } else {
      this.$store.dispatch(
        'herbarium/fetchDataFilter',
        `/herbarium${location.search}`
      )
      this.currentPage = queryString.parse(location.search).offset
    }
  },
  methods: {
    ...mapActions('herbarium', ['fetchData', 'changePerPage']),
    showGrid() {
      this.$router
        .push({
          name: 'HerbariumGrid'
        })
        .catch(() => {})
    },
    showList() {
      this.$router
        .push({
          name: 'HerbariumList'
        })
        .catch(() => {})
    },
    herbariumCreate() {
      this.$router
        .push({
          name: 'HerbariumCreate'
        })
        .catch(() => {})
    },
    fetchHerbariums(value) {
      this.$store.dispatch('changeCurrentPage', value)
      if (location.search) {
        this.$router
          .push({
            path: this.$route.path + this.changeQuery(location.search)
          })
          .catch(() => {})
        this.$store.dispatch(
          'herbarium/fetchDataFilter',
          `/herbarium${this.changeQuery(location.search)}`
        )
      } else {
        this.fetchData({ limit: this.getPerPage, offset: this.getCurrentPage })
      }
    },
    changeQuery(str) {
      const parsed = queryString.parse(str)
      parsed.limit = this.getPerPage
      parsed.offset = this.getCurrentPage
      return '?' + queryString.stringify(parsed)
    },
    changeShowItemsLess() {
      this.$store.dispatch('changePerPage', this.minItems)
      this.fetchHerbariums()
    },
    changeShowItemsMore() {
      this.$store.dispatch('changePerPage', this.maxItems)
      this.fetchHerbariums()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
}
</style>
